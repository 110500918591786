// Custom css
import './assets/css/portal-style-new.scss'
import 'maplibre-gl/dist/maplibre-gl.css'

import './assets/js/portal.js'

import './assets/img/favicon.png'

import Vue from 'vue'
import axios from 'axios'

import vuetify from './plugins/vuetify'

import store from './store/index'


// Setup global axios config 
Vue.use({
  install (Vue) {
    Vue.prototype.$api = axios.create({
      baseURL: '/api/v1/',
      headers: {'x-requested-with': 'XMLHttpRequest'}
    })
  }
})

new Vue({
  el: '#app',
  store,
  components: {
    'login': () => import('./components/pages/Login.vue'),
    'dashboard': () => import('./components/pages/Dashboard.vue'),
    'catalogue': () => import('./components/pages/Catalogue.vue'),
    'contact-management': () => import('./components/pages/ContactManagement.vue'),
    'find-my-pocket': () => import('./components/pages/FindMyPocket.vue'),
    'shop': () => import('./components/pages/Shop.vue'),
    'utility': () => import('./components/pages/utility/Index.vue'),
    'register-calibre-credential': () => import('./components/pages/utility/RegisterCalibreCredential.vue'),
    'generate-speaker-key': () => import('./components/pages/utility/GenerateSpeakerKey.vue'),
    'pop-up-message': () => import('./components/snippets/PopUpMessage.vue'),
    'setting': () => import('./components/pages/setting/Index.vue'),
    'change-password': () => import('./components/pages/setting/ChangePassword.vue'),
    'privacy-policy': () => import('./components/pages/PrivacyPolicy.vue'),
    'terms-and-conditions': () => import('./components/pages/TermsAndConditions.vue'),
    'contact-us': () => import('./components/pages/ContactUs.vue'),
    'error-page': () => import('./components/pages/ErrorPage.vue'),
    'bookshelf': () => import('./components/pages/Bookshelf.vue'),
    'favorite-radio': () => import('./components/pages/FavoriteRadio.vue'),
    'favorite-podcast': () => import('./components/pages/FavoritePodcast.vue'),
    'my-device': () => import('./components/pages/MyDevice.vue'),
  },
  mounted() {
    this.interceptAxios()
  },
  vuetify,
  methods: {
    dateToString(date) {
      var mm = date.getMonth() + 1; // getMonth() is zero-based
      var dd = date.getDate();

      return [date.getFullYear(),
          (mm>9 ? '' : '0') + mm,
          (dd>9 ? '' : '0') + dd
         ].join('-');
    },
    isParamsInUrl() {
      var url = window.location.href
      return url.split("?").length > 1
    },
    getParamsFromUrl() {
      return new URLSearchParams(window.location.search);
    },
    getCountryBasedOnDomain() {
      // Get the country of the website based on domain.
      // Currently only support realsam.co.uk (UK) and realsam.us (US)
      // Possible returned value us, uk or au
      let domain = window.location.hostname
      let countryDomain = domain.split(".").pop()
      let possibleCountry = ["us", "uk", "au"]
      
      if (possibleCountry.includes(countryDomain)) {
        return countryDomain
      }
      
      // Default country
      return "uk"
      
    },
    isUS(){
      let country = this.getCountryBasedOnDomain()
      if (["us"].includes(country)) {
				return true
			}
			return false
    },
    axiosRequest(method, url, params, successCallback, errorCallback, finallyCallback, headers=null){
      let config = {
        method: method,
        url: url,
      }

      if (method === "post") {
        // config.data must be an instance of URLSearchParams
        config.data = params
      } else if (method === "get") {
        config.params = params
      }

      if (headers) {
        config.headers = headers
      }

      let self = this

      this.$api.request(config)
      .then(response => {
        if ("action" in response.data) {
          if (response.data.action === "reauth") {
            return self.removeExpiredToken()
          }
        }
        successCallback(response)
      })
      .catch(error => {
        // console.log(error)
        errorCallback(error)
      })
      .finally(() => {
        finallyCallback()
      })
    },
    removeExpiredToken() {
      this.axiosRequest("post", "/auth/remove-expired-token/", {}, 
        (response) => {
          // On success.
          // console.log(response)
          if (response.data.status === "success") {
            window.location.href = response.data.redirect
          }
        }, 
        (error) => {
          // On error.
        },
        () => {
          // Finally
        })
    },
    interceptAxios() {
      var self = this
      this.$api.interceptors.response.use(function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        return response;
      }, function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        if (error.response) {
          // Request made and server responded
          console.log(error.response);
          if (error.response.status === 403 && error.response.headers.refresh_url) {
            // document.location.href = window.location.href;
            self.$root.$emit('popUpMessage', 'refreshSession')
          }
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
  
        return Promise.reject(error);
      });
    }
  }
});
