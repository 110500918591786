import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#131448',
        secondary: '#131448',
        accent: '#D0FFF2',

        error: colors.red.darken4,
        info: colors.blue.darken4,
        success: colors.green.darken4,
        warning: colors.orange.darken3,
      },
      dark: {
        primary: '#600040',
        secondary: '#131448',
        accent: '#D0FFF2',
        
        error: colors.red.darken4,
        info: colors.blue.darken4,
        success: colors.green.darken4,
        warning: colors.orange.darken3,
      },
    },
  },
  icons: {
    iconfont: 'mdiSvg',
  },
});
