window.onload = function() {
    
    const collapsibles = document.querySelectorAll('[data-toggle="collapse"]')
    collapsibles.forEach(el => {
        el.addEventListener('click', event => {
            const cullapseUlId = event.target.getAttribute("data-target")
            document.getElementById(cullapseUlId).classList.toggle('show')

            if (event.currentTarget.getAttribute("aria-expanded") == "true") {
                event.currentTarget.setAttribute("aria-expanded", "false")
            } else {
                event.currentTarget.setAttribute("aria-expanded", "true")
            }
        })
    })

    // Hadnling sidebar responsiveness including hamburger button
    var hamburgerBtn = document.getElementById('hamburger-btn');
    var hamburgerBtnIcon = document.getElementById('hamburger-btn-icon');
    var sidebar = document.getElementById('sidebar');
    
    if (hamburgerBtn) {
      hamburgerBtn.onclick = function(event) {
        if (!sidebar.classList.contains("open")) {
          sidebar.classList.add("open");
          hamburgerBtn.classList.remove("sidebar-closed");
    
          hamburgerBtnIcon.classList.remove("fa-bars");
          hamburgerBtnIcon.classList.add("fa-times");
    
        } else {
          sidebar.classList.remove("open");
          hamburgerBtn.classList.add("sidebar-closed");
    
          hamburgerBtnIcon.classList.add("fa-bars");
          hamburgerBtnIcon.classList.remove("fa-times");
        }
    
        event.preventDefault()
      }
    }
};