import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex)

export default new Vuex.Store({
	plugins: [createPersistedState({
		storage: window.sessionStorage,
	})],
	state: {
		username: '',
		fontSize: {
			size:3,
			card:'24px',
			cardDescription:'14px',
			cardLabel:'18px',
			body:'18px',
			btnToggle:'16px',
			btnLarge:'20px',
			btnStandart:'14px',
			textTable:'16px',
			headerFive:'24px',
			alert:'16px',
		},
		grayscale:false,
		highcontrast:false,
		negativecontrast:false
	},
	getters: {
		getUsername: state => {
			return state.username
		},
		getfontSize: state => {
			return state.fontSize
		},
		getGrayscale: state => {
			return state.grayscale
		},
		getHighcontrast: state => {
			return state.highcontrast
		},
		getNegativecontrast: state => {
			return state.negativecontrast
		}
	},
	mutations: {
		setUsername(state, payload) {
			state.username = payload
		},
		setfontSize(state, payload) {
			state.fontSize = {...state.fontSize,size:payload}
		},
		setGrayscale(state, payload) {
			state.grayscale = payload
		},
		setHighcontrast(state, payload) {
			state.highcontrast = payload
		},
		setNegativecontrast(state, payload) {
			state.negativecontrast = payload
		},
	},
	actions: {
		setUsername(context) {
			context.commit('setUsername')
		}, 
		setfontSize(context) {
			context.commit('setfontSize')
		}, 
		setGrayscale(context) {
			context.commit('setGrayscale')
		}, 
		setHighcontrast(context) {
			context.commit('setHighcontrast')
		}, 
		setNegativecontrast(context) {
			context.commit('setNegativecontrast')
		}
	}
})